<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ CurrentUser.display_name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="CurrentUser.profile_image" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{
            CurrentUser.display_name
              ? CurrentUser.display_name.charAt(0).toUpperCase()
              : ""
          }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-start justify-content-between px-4 py-2"
        style="
          background-image: url('/media/bg/gemic-logo-profile.jpg');
          background-size: cover;
        "
      >
        <!-- <h3 class="font-weight-bold m-0">User Profile</h3> -->
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-0">
          <div class="symbol symbol-100 mr-5">
            <v-img
              class="profile-image"
              width="80"
              height="80"
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
            >
            </v-img>
            <!-- <i class="symbol-badge bg-success"></i> -->
          </div>
          <div class="d-flex flex-column">
            <router-link
              :to="getDefaultRoute('profile.basic')"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ CurrentUser.full_name }}
            </router-link>
            <div class="d-flex align-center mt-1">
              <div
                class="text-muted text-uppercase"
                v-if="CurrentUser.role_name"
              >
                {{ CurrentUser.role_name }}
              </div>
              <v-icon size="8" class="mx-5 text-muted">mdi-circle</v-icon>
              <span
                class="label label-light-danger label-inline font-weight-bold mr-3"
              >
                Free
              </span>
              <router-link
                :to="getDefaultRoute('profile.my-account')"
                class="text-h6"
                >Upgrade</router-link
              >
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="
                          $assetURL(
                            'media/svg/icons/Communication/Mail-notification.svg'
                          )
                        "
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ CurrentUser.user_email }}
                  </span>
                </span>
              </a>
            </div>
            <div class="d-flex align-items-baseline">
              <div class="symbol symbol-25 bg-light mr-3">
                <div class="symbol-label bg-blue">
                  <span>{{ getAvatarText(appCompanyName) }}</span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{ appCompanyName }}</div>
              </div>
              <!-- <v-icon color="secondary" size="8" class="mx-2">mdi-circle</v-icon> -->
              <!-- <router-link to="" class="cursor-pointer">Switch</router-link> -->
            </div>
            <div class="text-h6 pt-3">
              <router-link
                :to="getDefaultRoute('profile.my-account')"
                class="cursor-pointer font-size-h5"
                >My Account</router-link
              >
              <v-icon size="8" class="mx-8 text-muted">mdi-circle</v-icon
              ><span
                class="text--red font-size-h6 cursor-pointer font-weight-bold"
                @click="onLogout"
                >Sign Out</span
              >
            </div>
          </div>
        </div>
        <!--end::Header-->
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-s"></i>
        </a>
      </div>
      <!--end::Header-->
      <div class="separator separator-dashed"></div>
      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content mr-n5 scroll px-8 py-3"
        style="max-height: 84vh; height: 84vh; position: relative"
      >
        <!-- <div class="separator separator-dashed mt-8 mb-5"></div> -->
        <div class="profile-info-align">
          <div class="about-info">
            <div class="" v-if="false">
              <div class="font-weight-bold font-size-h4 text-dark-75">
                My Organization
              </div>
              <div class="d-flex align-items-baseline pt-2">
                <div class="symbol symbol-30 bg-light mr-3">
                  <div class="symbol-label">
                    <span>BT</span>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">Business Thrust</div>
                </div>
                <!-- <v-icon color="secondary" size="8" class="mx-2">mdi-circle</v-icon> -->
                <!-- <router-link to="" class="cursor-pointer">Switch</router-link> -->
              </div>
            </div>
            <div class="pt-5" v-if="false">
              <div class="font-weight-bold font-size-h5 text-dark-75">
                Subscription
              </div>
              <div class="d-flex py-2">
                <div class="navi-text">
                  <div class="font-weight-bold">Free</div>
                </div>
                <v-icon color="secondary" size="8" class="mx-2"
                  >mdi-circle</v-icon
                >
                <router-link
                  :to="getDefaultRoute('profile.my-account')"
                  class="cursor-pointer"
                  >Upgrade</router-link
                >
              </div>
              <div class="navi navi-spacer-x-0 p-0">
                <router-link
                  :to="getDefaultRoute('profile.my-account')"
                  href="#"
                  class="navi-item"
                >
                  Upgrade to Genic FSM
                </router-link>
              </div>
            </div>
            <div class="pt-5">
              <div class="font-weight-bold font-size-h4 text-dark-75 pb-3">
                Other Information
              </div>
              <div class="navi navi-spacer-x-0 p-0">
                <v-row>
                  <v-col
                    md="6"
                    style="
                      padding-top: 5px !important;
                      padding-bottom: 5px !important;
                    "
                  >
                    <template v-if="CurrentUser.channel == 'customer'">
                      <template v-if="getPermission('user:view')">
                        <router-link
                          :to="
                            getDefaultRoute('user', {
                              query: {
                                status: 'active',
                              },
                            })
                          "
                          href="#"
                          class="navi-item"
                        >
                          <div class="navi-link py-0">
                            <div class="symbol symbol-30 bg-light mr-3">
                              <div class="symbol-label">
                                <span
                                  class="svg-icon svg-icon-md svg-icon-success"
                                >
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                    :src="
                                      $assetURL('media/svg/avatars/001-boy.svg')
                                    "
                                  />
                                  <!--end::Svg Icon-->
                                </span>
                              </div>
                            </div>
                            <div class="navi-text">
                              <div class="font-weight-bold">Admin Users</div>
                              <div class="text-muted">Manage admin users</div>
                            </div>
                          </div>
                        </router-link>
                      </template>
                    </template>
                  </v-col>
                  <v-col
                    md="6"
                    style="
                      padding-top: 5px !important;
                      padding-bottom: 5px !important;
                    "
                  >
                    <template v-if="CurrentUser.channel == 'customer'">
                      <template v-if="getPermission('user:view')">
                        <router-link
                          :to="
                            getDefaultRoute('engineer', {
                              query: {
                                status: 'all',
                              },
                            })
                          "
                          href="#"
                          class="navi-item"
                        >
                          <div class="navi-link py-0">
                            <div class="symbol symbol-30 bg-light mr-3">
                              <div class="symbol-label">
                                <span
                                  class="svg-icon svg-icon-md svg-icon-success"
                                >
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                    :src="
                                      $assetURL('media/svg/avatars/001-boy.svg')
                                    "
                                  />
                                  <!--end::Svg Icon-->
                                </span>
                              </div>
                            </div>
                            <div class="navi-text">
                              <div class="font-weight-bold">
                                Technical Users
                              </div>
                              <div class="text-muted">
                                Manage technical users
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </template>
                    </template>
                  </v-col>
                  <v-col
                    md="6"
                    style="
                      padding-top: 5px !important;
                      padding-bottom: 5px !important;
                    "
                  >
                    <!--begin::Item-->
                    <router-link
                      :to="getDefaultRoute('profile.activity')"
                      href="#"
                      class="navi-item"
                    >
                      <div class="navi-link py-0">
                        <div class="symbol symbol-30 bg-light mr-3">
                          <div class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-danger">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                :src="
                                  $assetURL(
                                    'media/svg/icons/Files/Selected-file.svg'
                                  )
                                "
                              />
                              <!--end::Svg Icon-->
                            </span>
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold">My Activities</div>
                          <div class="text-muted">Logs and notifications</div>
                        </div>
                      </div>
                    </router-link>
                    <!--end:Item-->
                  </v-col>
                  <v-col
                    md="6"
                    style="
                      padding-top: 5px !important;
                      padding-bottom: 5px !important;
                    "
                  >
                    <template v-if="CurrentUser.channel == 'customer'">
                      <template v-if="getPermission('setting:view')">
                        <router-link
                          :to="getDefaultRoute('profile.setting')"
                          href="#"
                          class="navi-item"
                        >
                          <div class="navi-link py-0">
                            <div class="symbol symbol-30 bg-light mr-3">
                              <div class="symbol-label">
                                <span
                                  class="svg-icon svg-icon-md svg-icon-success"
                                >
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                    :src="
                                      $assetURL(
                                        'media/svg/icons/Shopping/Settings.svg'
                                      )
                                    "
                                  />
                                  <!--end::Svg Icon-->
                                </span>
                              </div>
                            </div>
                            <div class="navi-text">
                              <div class="font-weight-bold">Setting</div>
                              <div class="text-muted">
                                Account settings and more
                                <span
                                  class="label label-light-danger label-inline font-weight-bold"
                                >
                                  update
                                </span>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </template>
                    </template>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="pt-12">
              <div class="font-weight-bold font-size-h4 text-dark-75 pb-3">
                Need Help?
              </div>
              <v-row>
                <v-col
                  md="6"
                  style="
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                  "
                >
                  <div class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <router-link to="" href="#" class="navi-item">
                      <div class="navi-link py-0">
                        <div class="symbol symbol-30 bg-light mr-3">
                          <div class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-danger">
                              <!--begin::Svg Icon-->
                              <!-- <inline-svg
                            :src="
                              $assetURL(
                                'media/svg/icons/Files/Selected-file.svg'
                              )
                            "
                          /> -->
                              <v-icon class="text--red" small
                                >mdi-headset</v-icon
                              >
                              <!--end::Svg Icon-->
                            </span>
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold">Talk to us</div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </v-col>
                <v-col
                  md="6"
                  style="
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                  "
                >
                  <div class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <router-link
                      to=""
                      class="navi-item"
                      @click.native="navigateToExternalUrlManual"
                    >
                      <div class="navi-link py-0">
                        <div class="symbol symbol-30 bg-light mr-3">
                          <div class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-danger">
                              <!--begin::Svg Icon-->

                              <v-icon class="text--red" small
                                >mdi-help-box</v-icon
                              >
                              <!--end::Svg Icon-->
                            </span>
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold">Help Resources</div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </v-col>
                <v-col
                  md="6"
                  style="
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                  "
                >
                  <div class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <!--  <router-link
                      to=""
                      href="mailto:support@genicteams.com"
                      class="navi-item"
                    > -->
                    <div class="navi-item">
                      <div class="navi-link py-0">
                        <div class="symbol symbol-30 bg-light mr-3">
                          <div class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-danger">
                              <!--begin::Svg Icon-->
                              <!-- <inline-svg
                            :src="
                              $assetURL(
                                'media/svg/icons/Files/email.svg'
                              )
                            "
                          /> -->
                              <v-icon class="text--red" small>mdi-email</v-icon>
                              <!--end::Svg Icon-->
                            </span>
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold">
                            <a
                              href="mailto:support@genicteams.com"
                              style="color: #3f4254"
                              >support@genicteams.com</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--   </router-link> -->
                  </div>
                </v-col>
                <v-col
                  md="6"
                  style="
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                  "
                >
                  <div class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <router-link
                      to=""
                      @click.native="navigateToExternalUrl"
                      class="navi-item"
                    >
                      <div class="navi-link py-0">
                        <div class="symbol symbol-30 bg-light mr-3">
                          <div class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-danger">
                              <!--begin::Svg Icon-->
                              <!-- <inline-svg
                            :src="
                              $assetURL(
                                'media/svg/icons/Files/book.svg'
                              )
                            "
                          /> -->
                              <v-icon class="text--red" small>mdi-book</v-icon>
                              <!--end::Svg Icon-->
                            </span>
                          </div>
                        </div>
                        <div class="navi-text">
                          <div class="font-weight-bold">Book a demo</div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="pb-15">
            <div class="addvertise">
              <v-img src="/media/bg/addvetise-img.png" width="100%"></v-img>
            </div>
            <div class="d-flex w-100 mt-4" v-if="false">
              <router-link to="" href="#" class="navi-item">
                <v-img
                  class="mr-3"
                  src="/media/logos/google-play.png"
                  contain
                  max-width="150"
                ></v-img>
              </router-link>
              <router-link to="" href="#" class="navi-item">
                <v-img
                  max-width="150"
                  src="/media/logos/apple_store.png"
                  contain
                ></v-img>
              </router-link>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <div
        class="powered_by d-flex align-center justify-content-end w-100 px-8 py-1"
      >
        <span
          class="d-flex align-center"
          style="font-size: 14px; font-weight: 400; color: #24326d"
          >Powered By
          <v-img
            class="mx-1"
            contain
            max-width="20"
            width="20"
            height="20"
            src="/favicon-32x32.png"
          ></v-img
          >Genic Teams</span
        >
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
.profile-info-align {
  flex-flow: column;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.powered_by {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import { LOGOUT, ENGINEER_LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [],
      CurrentUser: new Object(),
      userChannel: "customer",
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.getUserProfile();
  },
  methods: {
    navigateToExternalUrl() {
      window.open(
        "https://www.genicteams.com/book-demo/",
        "_blank",
        "noopener,noreferrer"
      );
    },
    navigateToExternalUrlManual() {
      window.open(
        "https://bthrustgrp.com/manual/know-base/",
        "_blank",
        "noopener,noreferrer"
      );
    },
    onLogout() {
      if (this.userChannel === "engineer") {
        this.$store
          .dispatch(ENGINEER_LOGOUT)
          .then(() => this.$router.push({ name: "engineer.login" }));
      } else {
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "admin.login" }));
      }
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    getUserProfile() {
      const _this = this;
      try {
        _this.CurrentUser = JwtService.currentUser();
        if (
          _this.lodash.isEmpty(_this.CurrentUser) === false &&
          _this.lodash.isObject(_this.CurrentUser)
        ) {
          _this.userChannel = _this.lodash.toString(_this.CurrentUser.channel);
        } else {
          _this.onLogout();
        }
      } catch (error) {
        _this.logError(error);
        _this.onLogout();
      }
    },
  },
  computed: {
    getProfileImage() {
      if (
        this.lodash.isEmpty(this.CurrentUser) === false &&
        this.lodash.isEmpty(this.CurrentUser.profile_logo) === false
      ) {
        return this.CurrentUser.profile_logo.file.url;
      }
      return null;
    },
  },
};
</script>
