<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <div class="d-flex align-items-center flex-wrap mr-1">
            <h5 class="text-dark font-weight-bold my-2 mr-5 text-capitalize">
              <span class="font-weight-600 color-custom-blue" v-if="title">{{
                lodash.toUpper(title)
              }}</span>
            </h5>

            <TopSearchbar
              v-if="isSearchActivated"
              :searchTitle.sync="searchTitle"
            ></TopSearchbar>

            <template v-else-if="breadcrumbs.length > 1">
              <v-breadcrumbs class="bt-breadcrumbs" :items="breadcrumbs">
                <template v-slot:item="{ item }">
                  <li v-if="item.route">
                    <router-link
                      :to="
                        getDefaultRoute(item.route, {
                          query: { status: 'all' },
                        })
                      "
                      class="v-breadcrumbs__item"
                    >
                      <span class="font-weight-600" v-if="item.barcode">{{
                        item.barcode
                      }}</span>
                      <span class="font-weight-600" v-else>{{
                        lodash.toUpper(item.title)
                      }}</span>
                    </router-link>
                  </li>
                  <li v-else>
                    <span class="font-weight-600" v-if="item.barcode">{{
                      item.barcode
                    }}</span>
                    <span class="font-weight-600" v-else>{{
                      lodash.toUpper(item.title)
                    }}</span>
                  </li>
                </template>
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </template>
          </div>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import TopSearchbar from "@/view/layout/header/TopSearchbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  name: "KTHeader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data: () => ({
    isSearchActivated: false,
    searchTitle: null,
  }),
  components: {
    KTTopbar,
    TopSearchbar,
  },
  methods: {
    getMenuTitle(title) {
      return title;
    },
  },
  beforeCreate() {
    const _this = this;
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      _this.$refs["kt_header_menu"],
      _this.$refs["kt_header_menu_wrapper"]
    );

    SearchEventBus.$on("search-template", (argument) => {
      _this.isSearchActivated = argument;
    });

    SearchEventBus.$on("search-module-title", (argument) => {
      _this.searchTitle = _this.lodash.capitalize(
        _this.lodash.lowerCase(argument)
      );
    });
  },
  beforeDestroy() {
    SearchEventBus.$off("search-template");
    SearchEventBus.$off("search-module-title");
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
